import React from "react";
import { Row, Col } from "antd";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

const Share = ({ socialConfig }) => (
  <Row className="post-social" justify="center">
    <Col lg={24} sm={22} xs={22}>
      <Row>
        <Col className="mr-15">
          <span className="icon-system-share" />
        </Col>
        <Col>
          <p className="mb-15">Comparte</p>
        </Col>
      </Row>
    </Col>
    <Col lg={24} sm={22} xs={22}>
      <FacebookShareButton
        url={socialConfig.config.url}
        quote={socialConfig.config.title}
        className="mr-10"
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <LinkedinShareButton
        url={socialConfig.config.url}
        quote={socialConfig.config.title}
        className="mr-10"
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <WhatsappShareButton
        url={socialConfig.config.url}
        quote={socialConfig.config.title}
        className="mr-10"
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
    </Col>
  </Row>
);

export default Share;
