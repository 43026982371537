import React from "react"
import { graphql, Link } from "gatsby"
import { Row, Col, Layout, Tag } from "antd"
import PublicLayout from "../components/PublicLayout"
import Share from "../components/Share"
import moment from "moment"
import "moment/locale/es"
import SEO from "../components/seo"
import imageUrlReplace from "./components/blog/imageUrlReplace"

moment.locale("es")

const { Content } = Layout;

const Post = (props) => {
  const {
    data: {
      wpgraphql: { post, posts },
    },
    pageContext: { categories },
  } = props;
  const countryCode = process.env.GATSBY_COUNTRY_VALUE.toLowerCase();

  const schemaExtraData = [{
    "@type": "Article",
  }];


  return (
    <PublicLayout page="post">
      <SEO
        title={post['meta_tag']['metaTitle'] && post['meta_tag']['metaTitle']}
        description={post['meta_tag']['metaDescription'] && post['meta_tag']['metaDescription']}
        image={imageUrlReplace(post.featuredImage.node.sourceUrl)}
        dataLayer={{
          userid: "",
          pageId: post.databaseId,
          pageType: "post",
          category: "Noticias",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className="post-container mt-lg-60 mb-lg-60">
        <Content>
          <Row justify='center'>
            <Col lg={24} xs={24}>
              <Row justify='center'>
                <Col xl={21} lg={22} xs={24}>
                  <Row gutter={[15, 46]}>
                    <Col lg={18} xs={24}>
                      <Row className='post-column-wrapper'>
                        <Col lg={{span: 24, order: 1}} xs={{span: 24, order: 2}}>
                          <Row className="current-tag-wrapper">
                            <Col>
                              <Link to={`/blog/${post.categories.nodes[0].slug}/`}>
                                <Tag className="category-tag">
                                  {post.categories.nodes[0].name}
                                </Tag>
                              </Link>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={{span: 24, order: 2}} xs={{span: 24, order: 1}}>
                          <h1 className="text-center">{post.title}</h1>
                          <p className="date text-center">
                            {moment(post.date, "YYYY-MM-DD").format("LL")}
                          </p>
                        </Col>
                        <Col lg={{span: 24, order: 3}} xs={{span: 24, order: 3}}>
                          <div
                            className="content-wrapper mb-lg-40"
                            dangerouslySetInnerHTML={{
                              __html: imageUrlReplace(post.content),
                            }}
                          />
                        </Col>
                        <Col lg={{span: 24, order: 4}} xs={{span: 24, order: 4}} className="mobile-share">
                          <Share
                            socialConfig={{
                              config: {
                                url: `https://www.cmtsalud.cl/blog/${post.slug}/`,
                                title: post.title,
                              },
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col lg={6} xs={24}>
                      <Row className='other-column-wrapper' gutter={[0, 30]}>
                        <Col lg={24} xs={24}>
                          <Row className="last-posts-wrapper">
                            <h2>
                              Entradas recientes
                            </h2>
                            <Col lg={24} xs={24}>
                              <Row gutter={[0, 10]}>
                              {
                                posts && posts.nodes.map((post, index) => {
                                  return (
                                    <Col lg={24} xs={24} key={index} className="image-container">
                                      <Link
                                        to={`/blog/${post.slug}/`}
                                        key={post.id}
                                        replace
                                      >
                                        <Row align="middle" gutter={8} className="pl-20 pr-20">
                                          <Col xl={7} lg={9} xs={9}>
                                            <div
                                              style={{
                                                backgroundImage: `url('${
                                                  imageUrlReplace(post.featuredImage.node.sourceUrl)
                                                }')`,
                                              }}
                                              className="image-post"
                                            />
                                          </Col>
                                          <Col xl={17} lg={15} xs={15}>
                                            <p className="post-title mb-0">{post.title}</p>
                                          </Col>
                                        </Row>
                                      </Link>
                                    </Col>
                                  )
                                })
                              }
                              </Row>
                            </Col>
                            <Col lg={24} xs={24} className="see-more-link">
                              <Link to={`/blog/`}>
                                Ver más
                              </Link>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={24} xs={24}>
                          <Row className="categories-wrapper">
                            <Col lg={24} xs={24}>
                              <h3>Ver entradas por categoría</h3>
                            </Col>
                            {
                              categories.map((category, index) => {
                                return (
                                  <Col className="mb-20" key={index}>
                                    <Link to={`/blog/${category.slug}/`}>
                                      <Tag
                                        className={`category-tag ${post.categories.nodes[0].name === category.name && 'active'}`}
                                      >
                                        {category.name}
                                      </Tag>
                                    </Link>
                                  </Col>
                                )
                            })}
                          </Row>
                        </Col>
                        <Col lg={24} xs={24} className="desktop-share">
                          <Row className="share-wrapper">
                            <Share
                              socialConfig={{
                                config: {
                                  url: `https://www.cmtsalud.${countryCode}/blog/${post.slug}/`,
                                  title: post.title,
                                },
                              }}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  );
};

export default Post;

export const pageQuery = graphql`
  query GET_POST($id: ID!, $ids: [ID], $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpgraphql {
      post(id: $id) {
        id
        title
        date
        excerpt
        content
        databaseId
        slug
        meta_tag {
          metaDescription
          metaTitle
        }
        categories {
          nodes {
            name
            slug
          }
        }
        featuredImage {
          node {
            sourceUrl
            altText
          }
        }
      }
      posts(where: { in: $ids }) {
        nodes {
          id
          title
          date
          excerpt
          content
          slug
          categories {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
        }
      }
    }
  }
`
